<template>
  <div class="m_professional">
    <m-title title="2023年度公需科目" />
    <div class="card_panel">
      <div class="panel">
        <div class="value">{{ Statistics.zongXue }}</div>
        <div class="label">已获总学时</div>
      </div>
      <div class="panel">
        <div class="value">{{ Statistics.zongTime }}</div>
        <div class="label">学习总时长</div>
      </div>
    </div>
    <m-title title="公需课在线考试" />
    <div class="courseList">
      <capsule-tab @click="selectCapsule" v-model="selectTab" :list="List" />
      <div class="list">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="item" v-for="(item, index) in dataList" :key="index">
            <div class="item_left">
              <div
                class="circle"
                :style="{
                  border:
                    item.status == 1 && item.ifQualified == 0
                      ? '3px solid #B50413'
                      : '3px solid #B5BCC9',
                }"
              ></div>
              <div style="margin: auto 10px">
                <div class="title">{{ item.courseName }}</div>
                <div class="achievement">考试成绩：{{ item.testScore }}分</div>
              </div>
            </div>
            <div
              @click="handlerExamination(item)"
              :class="
                item.status == 1 && item.ifQualified == 0 ? '' : 'disabled-link'
              "
            >
              <div class="button">去考试</div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>
<script>
import CapsuleTab from "@/components/capsuleTab.vue";
import {
  getGongXuUserCourseList,
  getPublicStatistics,
} from "@/api/publicDemandController";
import * as storage from "@/utils/storage";
import MTitle from "@/components/m_title.vue";

export default {
  name: "m_professional",
  components: {
    MTitle,
    CapsuleTab,
  },
  data() {
    return {
      loading: false,
      finished: false,
      dataList: [],
      selectTab: 0,
      List: [
        {
          label: "全部",
          value: 0,
        },
        {
          label: "未完成",
          value: 1,
        },
        {
          label: "待考试",
          value: 2,
        },
        {
          label: "考试通过",
          value: 3,
        },
      ],
      courseParams: {
        pageNo: 1,
        pageSize: 10,
        filterType: 0,
        total: 0,
        type: 0,
        ifExam: 1,
      },
      Statistics: {},
    };
  },
  computed: {
    yearId() {
      return storage.getStore("yearId");
    },
  },
  created() {
    this.handleRefresh();
  },
  activated() {
    this.inspectRefresh(this.handleRefresh);
  },
  methods: {
    handlerExamination(item) {
      this.keepAliveNavigation(`/examination?courseId=${item.studyCourseId}`);
    },
    handleRefresh() {
      this.getStatistics();
    },
    onLoad() {
      // 异步更新数据
      const that = this;
      getGongXuUserCourseList({
        trainingPlanId: storage.getStore("yearId"),
        ...that.courseParams,
      }).then((res) => {
        if (res.data.pageNo == 1) {
          that.dataList = res.data.data;
        } else {
          that.dataList.push(...res.data.data);
        }
        that.courseParams.pageNo++;
        if (that.dataList.length >= res.data.totalCount) {
          that.finished = true;
        }
        that.loading = false;
      });
    },
    selectCapsule(item) {
      this.courseParams.filterType = item.value;
      this.loading = false;
      this.finished = false;
      this.dataList = [];
      this.courseParams.pageNo = 1;
      this.onLoad();
    },
    // 获取统计
    getStatistics() {
      const that = this;
      getPublicStatistics({ trainingPlanId: this.yearId }).then((res) => {
        that.Statistics = res.data;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.m_professional {
  height: 100%;
  width: 100%;

  .card_panel {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    margin: 15px auto;

    .panel {
      padding: 10px 12px;
      text-align: center;
      background: rgba(251, 154, 162, 0.2);
      border-radius: 8px;

      .label {
        font-size: 12px;
      }

      .value {
        font-size: 16px;
      }
    }
  }

  .courseList {
    background: #ffffff;
    border-radius: 8px;
    padding: 12px 10px;
    margin: 10px auto;

    .list {
      .item {
        padding: 12px 15px;
        background: #f4f6f8;
        border-radius: 4px;
        margin-bottom: 12px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;

        .item_left {
          display: flex;
          align-items: center;
        }
        .circle {
          width: 10px;
          height: 10px;
          min-width: 10px;
          min-height: 10px;
          border: 3px solid #b50413;
          border-radius: 10px;
        }
        .title {
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          font-size: 14px;
        }
        .achievement {
          color: #818496;
          font-size: 12px;
        }
        .button {
          background: rgba(181, 4, 19, 0.1);
          padding: 3px 10px;
          width: max-content;
          border-radius: 240px;
          color: #b50413;
        }
        .disabled-link {
          pointer-events: none;
          cursor: not-allowed;
          color: #ccc;
          text-decoration: none;
          .button {
            background: rgba(181, 188, 201, 0.2);
            padding: 3px 10px;
            width: max-content;
            border-radius: 240px;
            color: #b5bcc9;
          }
        }
      }
    }
  }
}
</style>
